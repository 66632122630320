<script lang="ts">
	import classnames from 'classnames';
	import { onDestroy, onMount } from 'svelte';
	import { goto } from '$app/navigation';
	// Utils
	import { handleUrlClientSide } from '$lib/utils/assets-utils';
	import {
		getCourseByProductCode,
		hasVatFreeSession,
		lessionModeText,
		lessonModeIcon
	} from '$lib/utils/course-utils';
	import { dateFullMonthFormat, isValidDate, removeMasterCorsoLaurea } from '$lib/utils/formatter';
	import { Monitor } from '$lib/utils/monitor';
	import { getShortedSlugFromFull, hasShortSlug } from '$lib/utils/courses/slug';
	import { isCodingAcademy } from '$lib/utils/courses/coding-academy';
	import { isTodayBetweenTwoDates } from '$lib/utils/date';
	import { lazyLoader } from '$lib/utils/lazyloader';
	import { MBAUtils } from '$lib/utils/mba-utils';
	import {
		courseHasCFUCourse,
		getCFUCookie,
		getCourseWithCFUs,
		getCourseWithoutCFUs,
		isCFUCourse,
		setCFUCookie
	} from '$lib/utils/courses/cfu-courses';
	import { useCookiesStore } from '$lib/utils/cookie-store';
	// Stores
	import { page } from '$app/stores';
	import { CourseType, CourseTypeStore } from '$lib/utils/courses/type-store';
	import { GtmStore } from '$lib/utils/gtm-store';
	import { TagStore } from '$lib/utils/tag-store';
	// Components
	import {
		CourseLightModel,
		CourseModel,
		SessionLightModel,
		SessionModel,
		stringToSlug
	} from '@24hbs/utils';
	import { Icon } from '$lib/components/atoms/Icon';
	import {
		BadgeBestSeller,
		BadgeLatestSeats,
		BadgePromoDetails,
		BadgeCourseTypology
	} from '$lib/components/atoms/Badge';
	import { Price } from '$lib/components/atoms/Price';
	import { CourseCardTag } from '$lib/components/atoms/CourseCardTag';
	import {
		PUBLIC_CODING_ACADEMY,
		PUBLIC_ENABLE_BORSADISTUDIOFUORISEDE,
		PUBLIC_DISABLE_BORSADISTUDIOFUORISEDE
	} from '$env/static/public';
	//TODO: modal component
	//import CFUCourseModal from '@components/CFUCourseModal.svelte';

	export let course: CourseModel | CourseLightModel | SessionLightModel | SessionModel;
	export let lazyLoad: boolean = false;
	export let useSessionLink = false;
	export let gtmEventActive: boolean = false;
	export let gtmList: string = null;
	export let positionInPage: number = 0;
	export let isLastInList = false;

	let customLink = MBAUtils.isCourseOfTypeMBA(course) ? `/${MBAUtils.NORMAL_SLUG}` : '';

	const tagManagerStore = TagStore.getInstance();
	const tagReadyStore = tagManagerStore.tagReadyStore;
	const tagStore = tagManagerStore.tagStore;
	let lessonModeIconName: string | null = null;

	if (course.model === 'CourseModel') {
		course = CourseModel.fromJSON(course);
	}

	const mainSession: SessionModel | SessionLightModel =
		course instanceof CourseModel ||
		course instanceof CourseLightModel ||
		course.idMainSession !== undefined
			? course.getMainSession()
			: course;

	if (
		course.idMainSession !== undefined &&
		mainSession &&
		mainSession.idSession !== course.idMainSession
	) {
		useSessionLink = true;
	}

	useSessionLink = false;
	if (
		course.idMainSession !== undefined &&
		mainSession &&
		mainSession.idSession !== course.idMainSession
	) {
		useSessionLink = true;
	}

	$: lessonModeIconName = mainSession ? lessonModeIcon(mainSession.lessionMode) : 'ondemand';

	const courseLink = () => {
		if ($sessionAB === 'B' && courseHasCFUCourse(mainSession.productCode)) {
			return undefined;
		}
		if (customLink) return customLink;
		if (hasShortSlug(course.slug)) {
			return `/${getShortedSlugFromFull(course.slug)}`;
		}
		return `/formazione/${course.slug}${useSessionLink ? '/' + mainSession.idSession : ''}`;
	};

	const cookiesStore = useCookiesStore();
	const sessionAB = cookiesStore.sessionAB;
	let courseWithCFUProductCode: string;
	let courseWithoutCFUProductCode: string;
	let showCFUModal: boolean = false;
	let CFUCookieIsSet: boolean = false;

	const onClickWithoutCFU = async () => {
		console.log('onClickWithoutCFU');
		// setCFUCookie('without');
		let choosedCourse: CourseModel | CourseLightModel | SessionModel | SessionLightModel = course;
		let choosedMainSession: SessionModel | SessionLightModel = mainSession;
		if (isCFUCourse(mainSession.productCode)) {
			choosedCourse = await getCourseByProductCode(courseWithoutCFUProductCode, fetch);
			choosedMainSession = choosedCourse.getMainSession();
		}
		if (!CFUCookieIsSet) {
			GtmStore.pushEvent({
				event: 'BBEvent',
				category: 'CFU',
				action: 'NO',
				label: 'CFU',
				event_id: '1',
				page_path: `/${stringToSlug(choosedCourse.category)}/${choosedCourse.slug}`
			});
		}
		goToProductPage(choosedCourse, choosedMainSession);
	};

	const onClickWithCFU = async () => {
		console.log('onClickWithCFU');
		// setCFUCookie('with');
		let choosedCourse: CourseModel | CourseLightModel | SessionModel | SessionLightModel = course;
		let choosedMainSession: SessionModel | SessionLightModel = mainSession;
		if (!isCFUCourse(mainSession.productCode)) {
			choosedCourse = await getCourseByProductCode(courseWithCFUProductCode, fetch);
			choosedMainSession = choosedCourse.getMainSession();
		}
		if (!CFUCookieIsSet) {
			GtmStore.pushEvent({
				event: 'BBEvent',
				category: 'CFU',
				action: 'SI',
				label: 'CFU',
				event_id: '1',
				page_path: `/${stringToSlug(choosedCourse.category)}/${choosedCourse.slug}`
			});
		}
		goToProductPage(choosedCourse, choosedMainSession);
	};

	const goToProductPage = (
		course: CourseModel | CourseLightModel | SessionLightModel | SessionModel,
		mainSession: SessionModel | SessionLightModel
	) => {
		const courseUrl = `/formazione/${course.slug}`;
		const category = `${stringToSlug(mainSession.lessionMode)}/${stringToSlug(mainSession.courseTypeFull)}/${stringToSlug(mainSession.category)}`;
		GtmStore.pushCardClickEvent(
			{
				category: category,
				position: positionInPage,
				id: mainSession.productCode,
				name: mainSession.title,
				price: mainSession.discountPrice
					? mainSession.discountPrice.toString()
					: mainSession.priceNoVat.toString()
			},
			courseUrl
		);
		goto(courseUrl);
	};

	const cardClickHandler = () => {
		if ($sessionAB === 'B' && courseHasCFUCourse(mainSession.productCode)) {
			if (isCFUCourse(mainSession.productCode)) {
				courseWithCFUProductCode = mainSession.productCode;
				courseWithoutCFUProductCode = getCourseWithoutCFUs(mainSession.productCode);
			} else {
				courseWithCFUProductCode = getCourseWithCFUs(mainSession.productCode);
				courseWithoutCFUProductCode = mainSession.productCode;
			}

			// const CFUCookie: 'with' | 'without' | null = getCFUCookie();
			const CFUCookie: 'with' | 'without' | null = null;
			if (CFUCookie) {
				CFUCookieIsSet = true;
				if (CFUCookie === 'with') {
					onClickWithCFU();
				} else if (CFUCookie === 'without') {
					onClickWithoutCFU();
				}
				return;
			}
			GtmStore.pushEvent({
				event: 'pageview',
				pageCategory: 'popup_cfu',
				page_path: window.location.pathname
			});
			showCFUModal = true;
			return;
		}
		goToProductPage(course, mainSession);
	};

	let cardElement: HTMLElement;
	let monitor: Monitor;

	const onViewCard = () => {
		const category = `${stringToSlug(mainSession.lessionMode)}/${stringToSlug(mainSession.courseTypeFull)}/${stringToSlug(
			mainSession.category
		)}`;

		GtmStore.stashProduct({
			category: category,
			pagePath: $page.url.pathname,
			position: positionInPage,
			id: mainSession.productCode,
			name: mainSession.title,
			price: mainSession.discountPrice
				? mainSession.discountPrice.toString()
				: mainSession.priceNoVat.toString()
		});
	};

	onMount(async () => {
		tagManagerStore.updateTagsForSession(course, isLastInList);
		monitor = new Monitor(cardElement, onViewCard);
		monitor.startMonitoring();
	});

	onDestroy(() => {
		if (monitor) {
			monitor.stopMonitoring();
		}
	});

	let myColorfulTags: string[] = [];
	let myTextTags: string[] = [];

	$: if (
		isTodayBetweenTwoDates(
			PUBLIC_ENABLE_BORSADISTUDIOFUORISEDE,
			PUBLIC_DISABLE_BORSADISTUDIOFUORISEDE
		) &&
		[CourseType.MBAFullTime].includes(
			CourseTypeStore.getCourseTypeByCourseTypeName(mainSession.courseTypeFull)
		)
	) {
		myColorfulTags = TagStore.getColorfulTagForCourse($tagStore, course);
		myTextTags = ['borsa_di_studio_fuorisede'];
	} else if ($tagReadyStore) {
		myColorfulTags = TagStore.getColorfulTagForCourse($tagStore, course);
		myTextTags = TagStore.getTextualTagForCourse($tagStore, course);
	}
</script>

{#if course && mainSession}
	<section
		bind:this={cardElement}
		on:click={cardClickHandler}
		class="{CourseTypeStore.getCourseTypeCssClassByCourseType(
			CourseTypeStore.getCourseTypeByCourseTypeName(course.courseTypeFull)
		)} {classnames('course-card', $$props.class)}"
	>
		<div class="course-image-wrapper">
			{#if course.bestSeller}
				<div class="course-image-badge">
					<BadgeBestSeller />
				</div>
			{/if}
			<div class="course-image">
				{#if lazyLoad}
					<img
						class="image"
						use:lazyLoader={handleUrlClientSide(course.previewUrl)}
						alt={removeMasterCorsoLaurea(course.title)}
					/>
				{:else}
					<img
						class="image"
						loading="lazy"
						src={handleUrlClientSide(course.previewUrl)}
						alt={removeMasterCorsoLaurea(course.title)}
					/>
				{/if}
			</div>
		</div>

		<BadgeCourseTypology courseTypeFull={course.courseTypeFull} />

		<div class="course-content">
			<div class="course-info">
				{#if mainSession.productCode !== PUBLIC_CODING_ACADEMY}
					<p class="course-type">
						{course.courseTypeFull.toLocaleLowerCase() === 'master post laurea con stage'
							? `${course.courseTypeFull} garantito`
							: course.courseTypeFull}
					</p>
				{:else}
					<p class="course-type">CODING ACADEMY</p>
				{/if}
				<a class="course-link" href={courseLink()}>
					<h2 class="course-title">
						{removeMasterCorsoLaurea(
							course.title,
							mainSession.productCode ? mainSession.productCode : ''
						)}
					</h2>
				</a>

				<ul class="course-data">
					{#if isValidDate(mainSession.startDate)}
						<li class="course-data-item half">
							<span class="data-icon">
								<Icon icon="calendar" />
							</span>
							<span class="data-text">
								{#if mainSession.alwaysOn}
									Lezioni disponibili da subito
								{:else}
									{dateFullMonthFormat(mainSession.startDate)}
								{/if}
							</span>
						</li>
					{/if}

					{#if mainSession.alternativeSessions && mainSession.alternativeSessions.length > 0}
						<li class="course-data-item half">
							<div class="data-icon">
								<Icon icon="plus" />
							</div>
							<div class="data-text">
								<u>ALTRE DATE ({mainSession.alternativeSessions.length})</u>
							</div>
						</li>
					{:else}
						<li class="break" />
					{/if}

					{#if mainSession && mainSession.duration && mainSession.durationType}
						<li class="course-data-item half">
							<span class="data-icon">
								<Icon icon="duration" />
							</span>
							<span class="data-text">
								{#if mainSession.stage && mainSession.durationType == 'mesi' && (CourseTypeStore.getCourseTypeByCourseTypeName(mainSession.courseTypeFull) == CourseType.CorsiIntensiviStage || CourseTypeStore.getCourseTypeByCourseTypeName(mainSession.courseTypeFull) == CourseType.MasterFullTimeStage)}
									{mainSession.duration -
										(!isCodingAcademy(mainSession) &&
										CourseTypeStore.getCourseTypeByCourseTypeName(mainSession.courseTypeFull) ==
											CourseType.CorsiIntensiviStage
											? 4
											: 6)}
								{:else}
									{mainSession.duration}
								{/if}
								{mainSession.durationType}
							</span>
							{#if mainSession.stage && mainSession.durationType == 'mesi' && (CourseTypeStore.getCourseTypeByCourseTypeName(mainSession.courseTypeFull) == CourseType.CorsiIntensiviStage || CourseTypeStore.getCourseTypeByCourseTypeName(mainSession.courseTypeFull) == CourseType.MasterFullTimeStage)}
								<span class="stage-plus"> + </span>
								<span class="data-text">
									{#if CourseTypeStore.getCourseTypeByCourseTypeName(mainSession.courseTypeFull) == CourseType.CorsiIntensiviStage && !isCodingAcademy(mainSession)}
										4
									{:else}
										6
									{/if}
									MESI DI STAGE
								</span>
							{/if}
						</li>
					{/if}
					{#if mainSession.stage && mainSession.durationType != 'mesi' && CourseTypeStore.getCourseTypeByCourseTypeName(mainSession.courseTypeFull) != CourseType.CorsiIntensiviStage && CourseTypeStore.getCourseTypeByCourseTypeName(mainSession.courseTypeFull) != CourseType.MasterFullTimeStage}
						<li class="course-data-item half">
							<span class="data-icon">
								<Icon icon="stage" />
							</span>
							<span class="data-text"> STAGE</span>
						</li>
					{/if}
					{#if mainSession.lessionMode}
						<li class="course-data-item full">
							<span class="data-icon">
								<Icon icon={lessonModeIconName} />
							</span>
							<span class="data-text">
								{lessionModeText(mainSession.lessionMode).toUpperCase()}
							</span>
						</li>
					{/if}
				</ul>
				<CourseCardTag class="colorful-tags" tags={myColorfulTags} />
			</div>
			<div class="course-price">
				<div class="text-tags">
					{#if mainSession && (mainSession.credits_cfu || mainSession.credits_cfp)}
						<div class="credits">
							<Icon size="default" icon="my-courses" />
							{#if mainSession.credits_cfu}
								<span class="credits-text">CFU</span>
							{:else if mainSession.credits_cfp}
								<span class="credits-text">CFP</span>
							{/if}
						</div>
					{/if}
					<CourseCardTag tags={myTextTags} textOnly={true} />
				</div>
				<div class="card-price">
					<Price
						isPriceFrom={mainSession.alternativeSessions &&
							mainSession.alternativeSessions.length > 0}
						disableSoldOut={true}
						isInCard={true}
						session={mainSession}
						{course}
						hasVatFree={hasVatFreeSession(course)}
					/>
				</div>
			</div>
		</div>
		{#if mainSession.soldOut || mainSession.lastSeats}
			<div class="course-badge-footer">
				<BadgeLatestSeats session={mainSession} />
			</div>
		{:else if mainSession.promoDetailLabel}
			<div class="course-badge-footer">
				<BadgePromoDetails session={mainSession} />
			</div>
		{/if}
	</section>

	<!-- TODO: Work on it when AB Test over 
  {#if $sessionAB === 'B' && courseHasCFUCourse(mainSession.productCode)}
    <CFUCourseModal
      bind:open={showCFUModal}
      title={removeMasterCorsoLaurea(course.title,mainSession.productCode?mainSession.productCode:"")}
      on:click-without-cfu={onClickWithoutCFU}
      on:click-with-cfu={onClickWithCFU}
    />
  {/if}
  -->
{/if}

<style type="text/scss" lang="scss" scoped>
	.course-card {
		@apply grid;
		@apply relative;
		@apply w-full h-full;
		@apply rounded;
		@apply shadow-card-inset;
		@apply bg-white;
		@apply cursor-pointer;

		grid-template-rows: auto 1fr;

		/* Using pseudo elements for hover          effects on box-shadow
    is much better for performance
    See: https://tobiasahlin.com/blog/how-to-animate-box-shadow/ */

		&:before,
		&:after {
			@apply duration-500 transition-opacity ease-in-out;
		}

		/* Default State */
		&:before {
			content: '';
			@apply rounded;
			@apply absolute inset-0;
			@apply shadow-card opacity-100;
		}

		&:hover:before {
			@apply opacity-0;
		}

		/* Hover State */
		&:after {
			content: '';
			@apply rounded;
			@apply absolute inset-0;
			@apply shadow-card-hover opacity-0;
		}
		&:hover:after {
			@apply opacity-100;
		}

		&:hover {
			& .image {
				@apply transform scale-105;
			}
		}
	}

	.course-image {
		@apply overflow-hidden;
		@apply rounded-t;
		@apply aspect-w-3 aspect-h-1;
	}

	.course-image-badge {
		@apply overflow-hidden rounded-tl;
		@apply absolute top-0 left-0;
		@apply z-10;
	}

	.image {
		@apply object-cover;
		@apply transition-transform duration-500 ease-in-out;
		will-change: transform;
	}

	.course-content {
		@apply overflow-hidden;
		@apply p-6;
		@apply h-full;
		@apply flex flex-col;
	}
	.course-info {
		@apply flex-grow;
	}

	.course-brush {
		@apply inline-block relative -mt-4;
	}

	.course-type {
		@apply uppercase tracking-wide font-semibold;
		@apply text-gray-600 text-sm md:text-base;
	}

	.course-type + .course-link {
		@apply mt-2;
	}

	.course-title {
		@apply text-md md:text-2xl leading-tight;
	}

	.course-link {
		@apply inline-block mb-5;
		@apply text-gray-900 font-medium font-serif;

		&:after {
			content: '';
			@apply absolute inset-0;
			@apply z-10;
		}
	}

	.course-data {
		/* Space between list items */
		--space-x: theme('spacing.8'); /* 32px */
		--space-y: theme('spacing.4'); /* 20px */

		@screen md {
			--space-y: theme('spacing.5');
		}

		@apply flex flex-wrap;
		/* Trick to re-create flex-gap for browsers that don't support it */
		margin-left: calc(var(--space-x) * -1);
		margin-top: calc(var(--space-y) * -1);
	}

	.course-data-item {
		@apply flex items-center;
		@apply uppercase tracking-wide text-xs md:text-sm text-gray-900;
		margin-left: var(--space-x);
		margin-top: var(--space-y);

		&.items-start {
			//@apply items-start;
			align-items: flex-start;
		}

		&.full {
			@apply overflow-hidden;
			@apply w-full;

			.data-text {
				@apply w-full flex-auto;
				@apply overflow-ellipsis overflow-hidden whitespace-nowrap;
			}
		}

		&.half {
			@apply overflow-hidden;
			flex: 40%;
		}
	}

	.data-icon {
		@apply inline-flex flex-shrink-0 mr-2;
	}

	.data-text {
		@apply flex-shrink-0;
	}

	.course-price {
		--space-y: theme('spacing.4'); /* 20px */

		@screen md {
			--space-y: theme('spacing.5');
		}

		@apply flex;
		@apply border-t border-gray-600;
		padding-top: var(--space-y);
		margin-top: var(--space-y);
	}

	.text-tags {
		@apply flex flex-col mt-1;
		@apply items-start mb-2;
		flex-grow: 2;
	}

	.card-price {
		@apply self-end;
	}

	.credits {
		width: 90px;
		@apply flex items-center justify-center h-6;
		@apply bg-black rounded-md;
		@apply text-white;
	}
	.credits-text {
		@apply h-6 ml-2;
	}

	.course-badge-footer {
		@apply overflow-hidden rounded-b;
	}

	.break {
		flex-basis: 100%;
		height: 0;
	}

	.stage-plus {
		@apply ml-2 mr-2;
	}
</style>
