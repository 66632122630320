<script lang="ts">
  import classNames from "classnames";

  export let courseTypeFull: string;

  let typology: string;
  let typologyClassBadge: string;
  let typologyClassTr: string;
  
  if (courseTypeFull.toLowerCase().includes("stage")) {
    typology = "con stage";
    typologyClassBadge = "badge-stage"
    typologyClassTr = "tr-stage"
  } else if (courseTypeFull.toLowerCase().includes("career service")) {
    typology = "con career service";
    typologyClassBadge = "badge-career-service"
    typologyClassTr = "tr-career-service"
  } else {
    typology = "";
  }
</script>

{#if typology.length > 0}
  <div class='badge-typology-container'>
    <span class={classNames("badge tr", typologyClassBadge, typologyClassTr)}>
      {typology}
    </span>
  </div>
{/if}

<style type="text/scss" lang="scss" scoped>
.badge-typology-container {
  @apply absolute top-0 left-0;

  .badge {
    @apply block relative;
    @apply h-6;
    @apply pl-2 py-1;
    @apply text-sm uppercase font-semibold;
    @apply flex items-center;

    &.badge-stage {
      @apply text-gray-100;
      @apply bg-petrol;
    }

    &.badge-career-service {
      @apply text-black;
      @apply bg-gray-100;
    }
  
    &.tr:after {
      content: '';
      @apply block absolute;
      @apply w-6;
      @apply -right-6 top-0;
      border-right: 1.5rem solid transparent;
    }
    
    &.tr-stage:after {
      border-top: 1.5rem solid;
      @apply border-t-petrol;
    }

    &.tr-career-service:after {
      border-top: 1.5rem solid gray;
    }

  }
}
</style>
